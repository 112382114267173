import { adminClient } from "@api/admin/AdminClient";
import DateRangePickerComponent from "@components/admin/DateRangePickerComponent";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import { HomeItemsComponent } from "@components/admin/stats/HomeItemsComponent";
import HomeTransactionsComponent from "@components/admin/stats/HomeTransactionsComponent";
import StatsComponent from "@components/admin/stats/StatsComponent";
import { GraphPoint, GraphStat, mapDailyStatDateToDate, NormalizedStat, normalizeStats } from "@components/admin/stats/StatsHelper";
import HomeStackBarComponent from "@components/admin/transactions/HomeStackBarComponent";
import { queryApi } from "@hooks/api";
import { Box, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { AdminHandedInStats24HoursGetResponse, AdminHandedInStats48HoursGetResponse, AdminHandedInStats72HoursGetResponse, AdminHandedInStats7DaysGetResponse, AdminHandedInStatsAllAcceptedGetResponse, AdminHandedInStatsExtraGetResponse, AdminHomeTransactionsDatesAndCountsByDateRangeGetResponse, Countries } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";
import { endOfDay, isAfter, isEqual, setHours, startOfDay, subMonths } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

export default withAdminMenuLayout(function StatsPageComponent(): JSX.Element {

	const now = new Date();

	const [fromDate, setFromDate] = useState<Date>(subMonths(startOfDay(now), 1));
	const [toDate, setToDate] = useState<Date>(endOfDay(now));

	const [country, setCountry] = useState<Countries | "">("dk");

	const [stats, setStats] = useState<NormalizedStat[] | undefined>(undefined);

	const [handedInStatsAllAccepted, setHandedInStatsAllAccepted] = useState<AdminHandedInStatsAllAcceptedGetResponse | undefined>(undefined);
	const [handedInStatsHours24, setHandedInStatsHours24] = useState<AdminHandedInStats24HoursGetResponse | undefined>(undefined);
	const [handedInStatsHours48, setHandedInStatsHours48] = useState<AdminHandedInStats48HoursGetResponse | undefined>(undefined);
	const [handedInStatsHours72, setHandedInStatsHours72] = useState<AdminHandedInStats72HoursGetResponse | undefined>(undefined);
	const [handedInStatsDays7, setHandedInStatsDays7] = useState<AdminHandedInStats7DaysGetResponse | undefined>(undefined);
	const [handedInStatsExtra, setHandedInStatsExtra] = useState<AdminHandedInStatsExtraGetResponse | undefined>(undefined);



	const [homeTransactionsStats, setHomeTransactionsStats] = useState<AdminHomeTransactionsDatesAndCountsByDateRangeGetResponse | undefined>(undefined);
	const [homeTransactionsAcceptedCount, setHomeTransactionsAcceptedCount] = useState<number>(0);
	const [homeTransactionsDeclinedCount, setHomeTransactionsDeclinedCount] = useState<number>(0);
	const homeTransactionsCreated = useMemo(() =>
		homeTransactionsAcceptedCount + homeTransactionsDeclinedCount
		, [homeTransactionsAcceptedCount, homeTransactionsDeclinedCount]);

	var tenDaysAgo = new Date();
	tenDaysAgo.setDate(now.getDate() - 10);

	var oneYearAgo = new Date();
	oneYearAgo.setFullYear(now.getFullYear() - 1);
	oneYearAgo.setDate(oneYearAgo.getDate() + 5);

	var oneYearAndTenDaysAgo = new Date();
	oneYearAndTenDaysAgo.setDate(oneYearAndTenDaysAgo.getDate() - 10);
	oneYearAndTenDaysAgo.setFullYear(oneYearAndTenDaysAgo.getFullYear() - 1);

	const [itemStatsThisYear, setItemStatsThisYear] = useState<NormalizedStat[]>();
	const [itemStatsLastYear, setItemStatsLastYear] = useState<NormalizedStat[]>();

	const [emailNotificationStats, setEmailNotificationStats] = useState<any[]>();
	const [pushNotificationStats, setPushNotificationStats] = useState<any[]>();


	const isInvalidDateRange = useCallback(() => {
		return !fromDate || !toDate || isAfter(fromDate, startOfDay(new Date()));
	}, [fromDate]);

	useEffect(() => {
		if (isInvalidDateRange()) {
			setStats(undefined);
			setHomeTransactionsStats(undefined);
			setEmailNotificationStats(undefined);
			setPushNotificationStats(undefined);
		}
		else {
			setStatsFromDate();
			setHomeTransactionsStatFromData();
			setItemStatsFromTenDaysAgo();
			setItemStatsFromOneYearAndTenDaysAgo();
			setNotificationStatsFromDate();
			setHandedInStatsFromDateAllAccepted();
			setHandedInStatsFromDateHours24();
			setHandedInStatsFromDateHours48();
			setHandedInStatsFromDateHours72();
			setHandedInStatsFromDate7Days();
			setHandedInStatsFromDateExtra();
		}

		async function setStatsFromDate() {
			const stats = await adminClient().adminStatsTimeSeriesGet({
				fromDate: setHours(startOfDay(fromDate), 12),
				toDate: setHours(startOfDay(toDate), 12)
			});
			setStats(normalizeStats(stats.dailyStats));
		}

		async function setHandedInStatsFromDateAllAccepted() {
			const handedInStats = await adminClient().adminHandedInStatsAllAcceptedGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsAllAccepted(handedInStats)
		}
		async function setHandedInStatsFromDateHours24() {
			const handedInStats = await adminClient().adminHandedInStats24HoursGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsHours24(handedInStats)
		}
		async function setHandedInStatsFromDateHours48() {
			const handedInStats = await adminClient().adminHandedInStats48HoursGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsHours48(handedInStats)
		}
		async function setHandedInStatsFromDateHours72() {
			const handedInStats = await adminClient().adminHandedInStats72HoursGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsHours72(handedInStats)
		}
		async function setHandedInStatsFromDate7Days() {
			const handedInStats = await adminClient().adminHandedInStats7DaysGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsDays7(handedInStats)
		}
		async function setHandedInStatsFromDateExtra() {
			const handedInStats = await adminClient().adminHandedInStatsExtraGet({
				fromUtc: setHours(startOfDay(fromDate), 12),
				toUtc: setHours(startOfDay(toDate), 12)
			});
			setHandedInStatsExtra(handedInStats)
		}
	},
		[fromDate, toDate, isInvalidDateRange, country]
	)

	const [listOfCountries] = queryApi(
		async (options) => await adminClient().adminUserAddressListOfValidCountriesGet(options),
		[]);

	const spacing = 2;

	async function setHomeTransactionsStatFromData() {
		const homeTransActionsstats = await adminClient().adminHomeTransactionsDatesAndCountsByDateRangeGet({
			fromDate: setHours(startOfDay(fromDate), 12),
			toDate: setHours(startOfDay(toDate), 12),
			country: country
		});
		setHomeTransactionsStats(homeTransActionsstats)
		setHomeTransactionsAcceptedCount(homeTransActionsstats.homeTransactionsAcceptedCount);
		setHomeTransactionsDeclinedCount(homeTransActionsstats.homeTransactionsDeclinedCount);
	}

	async function setItemStatsFromTenDaysAgo() {
		const itemStatsThisYear = await adminClient().adminStatsTimeSeriesGet({
			fromDate: setHours(startOfDay(tenDaysAgo), 12),
			toDate: setHours(startOfDay(now), 12)
		});
		setItemStatsThisYear(normalizeStats(itemStatsThisYear.dailyStats));
	}

	async function setItemStatsFromOneYearAndTenDaysAgo() {
		const itemStatsLastYear = await adminClient().adminStatsTimeSeriesGet({
			fromDate: setHours(startOfDay(oneYearAndTenDaysAgo), 12),
			toDate: setHours(startOfDay(oneYearAgo), 12)
		});
		setItemStatsLastYear(normalizeStats(itemStatsLastYear.dailyStats));
	}

	async function setNotificationStatsFromDate() {
		const notificationStats = await adminClient().adminStatsTimeSeriesGet({
			fromDate: setHours(startOfDay(fromDate), 12),
			toDate: setHours(startOfDay(toDate), 12)
		});

		const pushNotificationStats = notificationStats.dailyStats
			.map(x => {
				return {
					date: mapDailyStatDateToDate(x.date),
					...((x.countries[country] as any)["pushNotification"])
				}
			})

		const emailNotificationStats = notificationStats.dailyStats
			.map(x => {
				return {
					date: mapDailyStatDateToDate(x.date),
					...((x.countries[country] as any)["emailNotification"])
				}
			})

		setPushNotificationStats(pushNotificationStats);
		setEmailNotificationStats(emailNotificationStats);
	}

	function generateGraphStats() {
		var result = new Array<GraphStat>();

		result.push({ title: "Accepted home transactions", data: generateAcceptedHomeTransactionsGraphPoints() } as GraphStat)
		result.push({ title: "Declined home transactions", data: generateDeclinedHomeTransactionsGraphPoints() } as GraphStat)

		return result;
	}


	function generateAcceptedHomeTransactionsGraphPoints() {
		return homeTransactionsStats?.homeTransactionsAccepted.map(stat => {
			return {
				x: stat.date,
				y1: stat.count
			} as GraphPoint
		});
	}

	function generateDeclinedHomeTransactionsGraphPoints() {
		return homeTransactionsStats?.homeTransactionsDeclined.map(stat => {
			return {
				x: stat.date,
				y1: stat.count
			} as GraphPoint
		});
	}

	function onCountryChange(event: React.ChangeEvent<{ value: unknown }>) {
		setCountry(event.target.value as Countries | "");
	}

	function onSubmit(from: Date | null, to: Date | null) {
		if ((!from || !to) || (isEqual(from, fromDate) && isEqual(to, toDate))) {
			return;
		}
		setStats(undefined);
		setFromDate(from);
		setToDate(to);
	}

	return <Box>
		<Box mb={spacing}>
			<Typography variant="h4">
				Stats
			</Typography>
		</Box>
		<Box mb={spacing}>
			<Box
				display="flex"
				flexDirection="reverse-row"
				alignItems="center"
				flexWrap="nowrap"
				justifyContent="flex-end"
			>
				<Box flexGrow={1} />
				<Box mb={spacing}>
					<FormControl
						fullWidth={true} style={{
							minWidth: "100px"
						}}>
						<InputLabel>
							Countries
						</InputLabel>
						<Select
							style={{
								marginRight: 16
							}}
							native={false}
							value={country}
							onChange={(e) => onCountryChange(e)}>
							<MenuItem value="">
								All
							</MenuItem>
							{listOfCountries && listOfCountries.countries?.map((c, index) => {
								return <MenuItem value={c.toLowerCase()} key={index}>
									{c.toUpperCase()}
								</MenuItem>
							})}
						</Select>
					</FormControl>
				</Box>
				<DateRangePickerComponent
					onSubmit={onSubmit}
					renderTimeOption={false}
					renderDateRanges={true}
					disableFutureDates={true}
					disablePastDates={false}
					submitOnLoad={false}
					variant="outlined"
					defaultFromDate={fromDate}
					defaultToDate={toDate}
				/>
			</Box>
		</Box>
		{!!itemStatsThisYear && !!itemStatsLastYear ? <>
			<Box
				display="flex"
				flexDirection="row"
				flexWrap="Wrap"
				mb={spacing}
			>
				<HomeItemsComponent
					cardTitle="ITEMS VIEWED"
					sizeNumberDividedWithHundred={3}
					statsThisYear={itemStatsThisYear}
					statsLastYear={itemStatsLastYear}
					country={country}
					keyForGraphPoints="itemViewed" />

				<HomeItemsComponent
					cardTitle="ITEMS CREATED"
					sizeNumberDividedWithHundred={3}
					statsThisYear={itemStatsThisYear}
					statsLastYear={itemStatsLastYear}
					country={country}
					keyForGraphPoints="itemCreated" />

				<HomeItemsComponent
					cardTitle="ITEMS SOLD"
					sizeNumberDividedWithHundred={3}
					statsThisYear={itemStatsThisYear}
					statsLastYear={itemStatsLastYear}
					country={country}
					keyForGraphPoints="itemSold" />
			</Box>
		</> : null}

		{isInvalidDateRange()
			? <div>Date Range is invalid</div>
			: <>
				{!!stats && !!homeTransactionsStats ?
					<><Box mb={spacing}>
						<HomeTransactionsComponent
							country={country}
							stats={generateGraphStats()}
							fromDate={fromDate}
							toDate={toDate}
							homeTransactionsAcceptedCount={homeTransactionsAcceptedCount}
							homeTransactionsDeclinedCount={homeTransactionsDeclinedCount}
							allHomeTransactionsCount={homeTransactionsCreated}
						/>
					</Box>

						<Box mb={spacing}>
							<StatsComponent
								country={country}
								stats={stats}
								fromDate={fromDate}
								toDate={toDate}
								keysForGraphs={keysForSubscriptionStats} />
						</Box>

						<Box mb={spacing}>
							<StatsComponent
								country={country}
								stats={stats}
								fromDate={fromDate}
								toDate={toDate}
								keysForGraphs={keysForBoostStats} />
						</Box>

						<Box mb={spacing}>
							<StatsComponent
								country={country}
								stats={stats}
								fromDate={fromDate}
								toDate={toDate}
								keysForGraphs={keysForUserStats} />
						</Box>

						<Box mb={spacing}>
							<Card>
								<CardContent style={{
									padding: 48
								}}>
									{emailNotificationStats && <HomeStackBarComponent
										stats={emailNotificationStats}
										title="Email Notifications"
									/>}
									{pushNotificationStats && <HomeStackBarComponent
										stats={pushNotificationStats}
										title="Push Notifications"
									/>}
								</CardContent>
							</Card>
						</Box>

						<Box mb={spacing}>
							<StatsComponent
								country={country}
								stats={stats}
								fromDate={fromDate}
								toDate={toDate}
								keysForGraphs={keysForEmailStats} />
						</Box>

						<Box mb={spacing}>
							<div>I perioden {fromDate.toDateString()}-{toDate.toDateString()} med i alt {handedInStatsAllAccepted?.allAccepted} accepterede pakker er indleveringsfordelingen: </div>
							<div>Indenfor 24 timer: {formatNumber(handedInStatsHours24?.last24HoursCount)} {handedInStatsHours24 != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsHours24?.last24HoursCount / handedInStatsAllAccepted.allAccepted) * 100) : ""} %</div>
							<div>Indenfor 48 timer: {formatNumber(handedInStatsHours48?.last48HoursCount)} {handedInStatsHours48 != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsHours48?.last48HoursCount / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
							<div>Indenfor 72 timer: {formatNumber(handedInStatsHours72?.last72HoursCount)} {handedInStatsHours72 != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsHours72?.last72HoursCount / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
							<div>Indenfor 7 dage: {formatNumber(handedInStatsDays7?.last7DaysCount)} {handedInStatsDays7 != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsDays7?.last7DaysCount / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
							<div>{handedInStatsExtra?.declinedCount} blev annulleret af sælger inden fristen {handedInStatsExtra != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsExtra?.declinedCount / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
							<div>{handedInStatsExtra?.expiredCount} udløb {handedInStatsExtra != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsExtra?.expiredCount / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
							<div>{handedInStatsExtra?.awaitingHandIn} mangler at blive handed in {handedInStatsExtra != undefined && handedInStatsAllAccepted != undefined ? formatNumber((handedInStatsExtra?.awaitingHandIn / handedInStatsAllAccepted?.allAccepted) * 100) : ""} %</div>
						</Box>
					</>
					: null}
			</>}
	</Box>
});

export interface StatsComponentProps {
	country: Countries | "",
	stats: NormalizedStat[],
	fromDate: Date | null,
	toDate: Date | null,
	keysForGraphs: KeysForGraphsModel[]
}

export interface HomeTransactionsComponentsProps {
	country: Countries | "",
	stats: GraphStat[],
	fromDate: Date | null,
	toDate: Date | null,
	homeTransactionsAcceptedCount: number,
	homeTransactionsDeclinedCount: number,
	allHomeTransactionsCount: number
}

export interface KeysForGraphsModel {
	key: string,
	hasGraph: boolean,
	hasTotal: boolean,
	title: string,
	unknownCountry?: boolean
}

const keysForItemStats = [
	{
		key: "itemViewed",
		title: "Items viewed",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemCreated",
		title: "Items created",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemSold",
		title: "Items sold",
		hasGraph: true,
		hasTotal: true
	}
];

const keysForSubscriptionStats = [
	{
		key: "subscriptionSignup",
		title: "Created subscriptions",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "subscriptionExtended",
		title: "Extended subscriptions",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "subscriptionTerminated",
		title: "Terminated subscriptions",
		hasGraph: true,
		hasTotal: true
	}
];

const keysForBoostStats = [
	{
		key: "itemPromoted",
		title: "Promoted items",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemBoosted",
		title: "Boosted items",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "itemPingFavoritingUsers",
		title: "Ping favorites",
		hasGraph: true,
		hasTotal: true
	}
];

const keysForUserStats = [
	{
		key: "userCreated",
		title: "Created users",
		hasGraph: true,
		hasTotal: true
	},
	{
		key: "userDeleted",
		title: "Deleted users",
		hasGraph: true,
		hasTotal: true
	}
];

const keysForEmailStats = [
	{
		key: "sendGride-mailsSent",
		title: "SendGrid e-mails",
		hasGraph: true,
		hasTotal: true
	}
];

