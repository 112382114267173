import { Typography } from '@material-ui/core';
import { toDanishDateFormat } from '@utils/miscellaneous';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';


export default function HomeStackBarComponent(props: {
  stats: any[]
  title: string
}) {

  const getStatCategoryNames = () => {
    return Object.keys(props.stats[0]).filter(x => x !== "date");
  }

  const barColors: string[] = ["#031228", "#072451", "#0a3579", "#0d47a1", "#4a75b9", "#86a3d0"];

  return (
    <>
      <Typography variant="h6" style={{
        marginTop: 24
      }}>
        {props.title}
      </Typography>

      <ResponsiveContainer
        height={500}
        width={"100%"}>

        <BarChart
          data={props.stats}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
        >
          <CartesianGrid
            horizontal={false}
            vertical={false}
          />
          <XAxis
            type="number"
          />
          <YAxis
            dataKey="date"
            type="category"
            tickFormatter={(tick) => {
              return toDanishDateFormat(tick, "dd/MM")
            }}
          />
          <Tooltip />
          <Legend />
          {getStatCategoryNames().map((x, index) => <Bar
            dataKey={x as string}
            stackId="a"
            fill={barColors[index]} />)}
        </BarChart>

      </ResponsiveContainer>
    </>
  );
}