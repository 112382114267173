import HomeTransactionsCountComponent from "@components/admin/stats/HomeTransactionsCountComponent";
import LineChartSectionComponent from "@components/admin/stats/LineChartSectionComponent";
import { GraphStat } from "@components/admin/stats/StatsHelper";
import StatsSkeleton from "@components/admin/stats/StatsSkeleton";
import { Box, Card, CardContent } from "@material-ui/core";
import { HomeTransactionsComponentsProps } from "src/pages/admin/stats";

export default function HomeTransactionsComponent(props: HomeTransactionsComponentsProps): JSX.Element {

	const numberOfBoxes = 3
	const numberOfCharts = 1

	if (props.stats === undefined) {
		return <StatsSkeleton noOfBoxes={numberOfBoxes} noOfCharts={numberOfCharts} />
	}

	const listOfStatsFromKeys: GraphStat[][] = [];

	return <Card>
		<CardContent style={{
			padding: 48
		}}>
			<Box display="flex" flexDirection="row" style={{
				marginBottom: 48
			}}>
				<HomeTransactionsCountComponent
					title={"Home Transactions created"}
					count={props.allHomeTransactionsCount}
					width={100 / numberOfBoxes + "%"} />
				<HomeTransactionsCountComponent
					title={props.homeTransactionsAcceptedCount ? "Home Transactions accepted (" + ((props.homeTransactionsAcceptedCount / props.allHomeTransactionsCount) * 100).toFixed(1) + "%)" : "Home Transactions accepted (0%)"}
					count={props.homeTransactionsAcceptedCount}
					width={100 / numberOfBoxes + "%"} />
				<HomeTransactionsCountComponent
					title={props.homeTransactionsDeclinedCount ? "Home Transactions declined (" + ((props.homeTransactionsDeclinedCount / props.allHomeTransactionsCount) * 100).toFixed(1) + "%)" : "Home Transactions declined (0%)"}
					count={props.homeTransactionsDeclinedCount}
					width={100 / numberOfBoxes + "%"} />
			</Box>
			<LineChartSectionComponent
				stats={props.stats}
				title={"Home transactions"}
				from={props.fromDate!}
				to={props.toDate!}
				displayZValue={false} />
		</CardContent>
	</Card>
}

