import { Box, Typography } from "@material-ui/core";
import { formatLargeNumber } from "@utils/formatting";

export default function HomeTransactionsCountComponent(props: {title: string, count: number, width: string}) {
    return <Box width={props.width}>
      <Box display="flex" justifyContent="center">
        <Typography style={numberStyle}>
          {props.count === undefined ? 0 : formatLargeNumber(props.count)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography style={textStyle}>
          {props.title}
        </Typography>
      </Box>
    </Box>;
}

const numberStyle = {fontSize: "25pt"}
const textStyle = {fontSize: "12pt"}
