import LineChartSectionComponent from "@components/admin/stats/LineChartSectionComponent";
import { GraphPoint, GraphStat, NormalizedStat } from "@components/admin/stats/StatsHelper";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { Countries } from "@reshopper/admin-client";
import { getISOWeek, setDay, setHours, startOfDay } from "date-fns";
import { sumBy } from "lodash";
import style from "./HomeItemsComponent.module.scss";

export function HomeItemsComponent(props: {
	cardTitle: string,
	sizeNumberDividedWithHundred?: number,
	statsThisYear: NormalizedStat[],
	statsLastYear: NormalizedStat[],
	country: Countries | "",
	keyForGraphPoints: string
}) {
	const keys = ["last year", "this year"];

	const now = new Date();

	var tenDaysAgo = new Date();
	tenDaysAgo.setDate(now.getDate() - 10);

	function getDates(startDate: Date, stopDate: Date) {
		var dateArray: Date[] = [];
		while (startDate <= stopDate) {
			dateArray.push(setHours(startOfDay(startDate), 0))
			startDate.setDate(startDate.getDate() + 1)
		}
		return dateArray;
	}

	const dateRange = getDates(tenDaysAgo, now);

	function generateGraphStats() {
		const data: GraphStat[] = [];

		if (!props.statsThisYear || !props.statsLastYear) {
			return data;
		}

		for (let i = 0; i < keys.length; i++) {
			let statsThisYear = dateRange.map(date => { return {
				x: date,
				y1: getValueByKeyAndDate(props.statsThisYear, props.keyForGraphPoints, date)
			} as GraphPoint })
			let statsLastYear = dateRange.map(date => { return {
				x: date,
				y1: getValueByKeyAndDate(props.statsLastYear, props.keyForGraphPoints, sameDayLastYear(date))
			} as GraphPoint})

			data.push({
				title: keys[i] === "last year" ? (new Date().getFullYear() - 1).toString() : (new Date().getFullYear()).toString(),
				data: keys[i] === "last year" ? statsLastYear : statsThisYear
			} as GraphStat)
		}

		return data;
	}

	function getValueByKeyAndDate(data: NormalizedStat[], key: string, date: Date) {
		return sumBy(
			data.filter(
				stat => stat.country === "dk" &&
					stat.key === key &&
					stat.category === null &&
					stat.date.getTime() === date.getTime()),
			stat => stat.value);
	}

	function sameDayLastYear(date: Date) {
		var sameDateLastYear = new Date(date.getTime());
		sameDateLastYear.setFullYear(date.getFullYear() - 1);

		var sameDayLastYear = setDay(new Date(sameDateLastYear), date.getDay());

		if (getISOWeek(date) != getISOWeek(sameDayLastYear)) {
			sameDayLastYear.setDate(sameDayLastYear.getDate() + 7)
		}

		return sameDayLastYear;
	}

	function CardTitle(props: {
		title: string
	}) {
		return <CardHeader
			className={style.cardTitle}
			title={
				<Typography variant="h2" style={{ fontWeight: "bold" }}>{props.title}</Typography>
			}>
		</CardHeader>
	}

	return <Card
		style={{
			flexGrow: 1,
			maxWidth: props.sizeNumberDividedWithHundred ? "calc((100% / " + props.sizeNumberDividedWithHundred + ") - 20px)" : "100%",
			margin: 10
		}}>
		<CardTitle title={props.cardTitle} />
		<CardContent
			style={{
				backgroundColor: "#ffffff",
				padding: 20
			}}>

			<LineChartSectionComponent
				stats={generateGraphStats()}
				from={tenDaysAgo}
				to={now}
				xAxisTickFormat={"ccc"} />

		</CardContent>
	</Card>
}